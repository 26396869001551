import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListService = params => request.get('/api/v1/services', { params });
export const getListCodeService = params => request.get('/api/v1/stores', { params });
export const getListServiceBO = params => request.get('/api/v1/services-in-bo', { params });
export const fastServiceCreate = data => request.post('/api/v1/phone-service', data);
export const fastServices = params => request.get('/api/v1/phone-service', { params });
export const historyServices = params => request.get('/api/v1/history', { params });
export const historyRentPhoneBO = (params,headers) => request.get('/api/v1/phone-service/get-list', { params,...headers });
export const exportHistoryServices = (params, headers) => request.get('/api/v1/history/export', { params, ...headers });
export const createService = data => request.post('/api/v1/services/create', data);
export const createCodeService = data => request.post('/api/v1/stores/create', data);
export const updateService = data => request.post('/api/v1/services/update', data);
export const updateCodeService = data => request.post('/api/v1/stores/update', data);
export const deleteService = data => request.post('/api/v1/services/delete', data);
export const deleteCodeService = data => request.post('/api/v1/stores/delete', data);
export const setBestService = data => request.post('/api/v1/services/set-best', data);
export const sendRequestZalo = data => request.post('/api/v1/phone-service/send-request-zalo', data);
export const reSendRequestZalo = data => request.post('/api/v1/phone-services/re-get-otp', data);
export const sendRequestVoice = data => request.post('/api/v1/phone-service/phone-service/send-voice-otp', data);
export const setPublishService = data => request.post('/api/v1/services/change-status', data);
export const exportTemplate = (data) => request.post('/api/v1/categories/export-template', data, { responseType: 'arraybuffer' })
export const importFile = (data, config) =>
  request.post('/api/v1/categories/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const getStatistics = (params) => request.get('/api/v1/statistics-transactions', {params})
export const getSuccesBuyPhone = (params) => request.get('/api/v1/phone-services/statistics', {params})


export const updateThumbnail = async data => {
  return await request.post('/api/v1/services/upload-thumbnail', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Mutation
export const useCreateServiceMutation = () => useMutation({ mutationFn: createService });
export const useRESendRequestZaloMutation = () => useMutation({ mutationFn: reSendRequestZalo });
export const useSendRequestZaloMutation = () => useMutation({ mutationFn: sendRequestZalo });
export const useSendRequestVoiceOtpMutation = () => useMutation({ mutationFn: sendRequestVoice });
export const useDeleteCodeServiceMutation = () => useMutation({ mutationFn: deleteCodeService });
export const useCreateFastServiceMutation = () => useMutation({ mutationFn: fastServiceCreate });
export const useUpdateServiceMutation = () => useMutation({ mutationFn: updateService });
export const useDeleteServiceMutation = () => useMutation({ mutationFn: deleteService });
export const useSetBestServiceMutation = () => useMutation({ mutationFn: setBestService });
export const useSetPublishMutation = () => useMutation({ mutationFn: setPublishService });
export const useCodeServiceMutation = () => useMutation({ mutationFn: createCodeService });
export const useCodeServiceUpdateMutation = () => useMutation({ mutationFn: updateCodeService });
export const useUpdateThumbnailMutation = () => useMutation({ mutationFn: updateThumbnail });
export const useExportTemplateMutation = () => useMutation({ mutationFn: exportTemplate });
export const useExportHistoryServicesMutation = (params, headers) => useMutation({ mutationFn: () => exportHistoryServices(params, headers) });
export const useLisHistoryMutation = (params, headers) => useMutation({ mutationFn: () => historyRentPhoneBO(params, headers) });
// Query
export const useQueryGetListServiceBO = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListServiceBO', params], queryFn: () => getListServiceBO(params), ...options });
export const useQueryGetListService = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListService', params], queryFn: () => getListService(params), ...options });
export const useQueryGetListFastService = (params = {}, options = {}) =>
  useQuery({ queryKey: ['fastServices', params], queryFn: () => fastServices(params), ...options });
export const useQueryGetHistoryService = (params = {}, options = {}) =>
  useQuery({ queryKey: ['historyServices', params], queryFn: () => historyServices(params), ...options });
export const useQueryGetHistoryRentPhoneBO = (params = {}, options = {}) =>
  useQuery({ queryKey: ['historyRentPhoneBO', params], queryFn: () => historyRentPhoneBO(params), ...options });
export const useQueryGetCodeService = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListCodeService', params], queryFn: () => getListCodeService(params), ...options });

export const useQueryGetStatistics = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_STATISTICS', params], queryFn: () => getStatistics(params), ...options });
export const useQueryGetSuccesBuyPhone = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_SUCCESS_BUY_PHONE', params], queryFn: () => getSuccesBuyPhone(params), ...options });