import { Box, Checkbox, Flex, IconButton, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { formatDate, formatDateVN } from 'utils/helpers';
import { FORMAT_DATE } from 'constants/common';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useChangeStatusMutation, useDeletePhoneMutation, useDeletePhonesMutation } from 'services/phone';
import { FaClipboardList } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const ProductTable = ({ onOpen, productsData, refetch, setPhoneDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();
  const history = useHistory();

  const deletePhoneMutation = useDeletePhoneMutation();
  const deletePhonesMutation = useDeletePhonesMutation();
  const changeStatusPhoneMutation=useChangeStatusMutation()
  const handleDeleteSize = product => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa số điện thoại này không?');
    if (!confirmDelete) {
      return;
    }

    deletePhoneMutation.mutate(
      { id: product?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa số điện thoại thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa số điện thoại thất bại');
          refetch?.();
        },
      }
    );
  };
  const onActionPhone = (phoneNumber, title) => {
    changeStatusPhoneMutation.mutate(
      { phoneNumber },
      {
        onSuccess: () => {
          toast.showMessageSuccess(`${title} thành công`);
          refetch?.();
        },
        onError: () => {
          toast.showMessageError(`${title} thất bại`);
          refetch?.();
        },
      }
    );
  };
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item._id]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((i) => i !== item._id)
      );
    }
  };
  
  const columns = useMemo(
    () => [
      columnHelper.accessor('checkbox', {
        header: 'Chọn',
        cell: (info) => (
          <Checkbox
            isChecked={info.row.original.isChecked}
            onChange={(e) => handleCheckboxChange(info.row.original, e.target.checked)}
          />
        ),
      }),
      columnHelper.accessor('phone', {
        header: 'Số điện thoại',
        cell: info => <Box textTransform="capitalize">{info.renderValue()}</Box>,
      }),
      columnHelper.accessor('balance', {
        header: 'TKC',
        cell: info => <Box textTransform="capitalize">{info.renderValue()} VND</Box>,
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => (
          <Switch
            size="md"
            isChecked={info.getValue()}
            onChange={e => onActionPhone(info?.row?.original?.phone, 'Thay đổi trạng thái')}
          />
        ),
      }),
      columnHelper.accessor('countryCode', {
        header: 'Quốc gia',
        cell: info => info.renderValue(),
      }),
      columnHelper.accessor('expiredAt', {
        header: 'HSD',
        cell: info => formatDateVN(info.getValue(),'DD/MM/YYYY'),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => formatDateVN(info.getValue()),
      }),

      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  history.push(`/admin/${info?.row?.original?.phone}/services`)
                }}
              >
                <FaClipboardList cursor="pointer" boxSize={4} />
              </IconButton>
              <IconButton
                bg="transparent"
                onClick={() => {
                  setPhoneDetail(info?.row?.original);
                  onOpen();
                }}
              >
                <EditIcon cursor="pointer" boxSize={4} />
              </IconButton>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleDeleteSize(info?.row?.original);
                }}
              >
                <DeleteIcon color="red.400" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
          </Flex>
        ),
      }),
    ],
    [productsData]
  );

  const table = useReactTable({
    data: productsData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" isTruncated>
                {header.isPlaceholder ? null : (
                  <Box
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                    isTruncated
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={10}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} isTruncated>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
    </>
  
  );
};

export default ProductTable;
