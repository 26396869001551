import { Box, Flex, IconButton, Table, Tag, TagLabel, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE } from 'constants/common';
import { formatCurrencyToVND, formatDate, formatDateVN, getCodeFromMsg } from 'utils/helpers';
import { renderLabelColor } from 'utils/styles';
import { FaRegCopy } from 'react-icons/fa';
import { toast } from 'components/Toast';
import { FaPlayCircle } from "react-icons/fa";
const SizeTable = ({ categoryData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('', {
        header: 'STT',
        cell: info => (info.row.index + 1),
      }),
      columnHelper.accessor('service', {
        header: 'Dịch vụ thuê',
        cell: info => info.getValue()?.title || '--',
      }),
      columnHelper.accessor('member', {
        header: 'Khách hàng',
        cell: info =>  info.renderValue()?.username,
      }),
      columnHelper.accessor('phoneNumber', {
        header: 'Số thuê',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('code', {
        header: 'Mã',
        cell: info =>
          info.getValue() ? (
            <Flex alignItems={"center"} gap={2}>
              <Text>{getCodeFromMsg(info?.row?.original?.content)}</Text>
              <FaRegCopy
                cursor={'pointer'}
                onClick={() => {
                  navigator?.clipboard?.writeText(getCodeFromMsg(info?.row?.original?.content));
                  toast.showMessageSuccess('Đã copy mã');
                }}
              />
            </Flex>
          ) : (
            '--'
          ),
      }),
      columnHelper.accessor('audioRecordUrl', {
        header: 'Cuộc gọi',
        cell: info => 
          info.getValue() ?
         <Box
        bg="transparent"
      >
        <audio controls>
        <source src={`https://sv.hotro.ink/${info.getValue()}`} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
      </Box>
        : '--',
      }),
      // columnHelper.accessor('content', {
      //   header: 'Nội dung',
      //   cell: info => info.getValue() ? <Text whiteSpace={'pre-wrap'}>{info.getValue()}</Text> : '--',
      // }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => (
          <Tag colorScheme={renderLabelColor(info.getValue())}>
            <TagLabel>{info.getValue()}</TagLabel>
          </Tag>
        ),
      }),
      columnHelper.accessor('price', {
        header: 'Xu',
        cell: info =>formatCurrencyToVND(info.getValue()),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDateVN(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
    ],
    [categoryData]
  );

  const table = useReactTable({
    data: categoryData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} maxW={200} maxWidth="200px" isTruncated>
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id} isTruncated maxW={200} maxWidth="200px">
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
